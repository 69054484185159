import React, {useState} from "react";
import {
    Button,
    Checkbox,
    Form, notification, Select, Tooltip,
} from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import {useMultiSelectManualTrigger, useGetGrindorWarehouseGroups, GrindorMultiSelectTriggerBody} from "../../../api/grindor";
import { FORCE_TRIGGER_TOOLTIP } from './constants';







interface GrindorMultiSelectTriggerParams {
    slug: string;
    products: string[];
    userName: string;
}


export const GrindorMultiSelectTrigger: React.FC<GrindorMultiSelectTriggerParams> = (
    {
        slug,
        products,
        userName,
    }) => {

    const [warehouse_group, setWarehouseGroup] = useState<string | null>(null)
    const [forceTrigger, setForceTrigger] = useState(false)
    const [fullRefresh, setFullRefresh] = useState(false)
    const [triggerDependentJobs, setTriggerDependentJobs] = useState(false)

    const {
		data: warehouseGroups,
		loading: warehouseGroupsLoading,
	} = useGetGrindorWarehouseGroups();

    const {
        loading: loadingManualTrigger,
        runAsync: manualTrigger
    } = useMultiSelectManualTrigger({
        onSuccess: (response) => {
            notification.success({message: 'Grindor triggered'});
        },
        onError: (error) => {
            notification.error({
                message: 'Failed triggering grindor',
                description: error.toString()
            });
        }
    })

    return <div>
        <div style={{ marginBottom: '24px' }}>
            Force Trigger: <Checkbox
                checked={forceTrigger}
                onChange={(e) => {
                    setForceTrigger(e.target.checked)
                }}
            /> <Tooltip title={FORCE_TRIGGER_TOOLTIP}>
                <QuestionCircleOutlined style={{ marginLeft: '4px', color: '#999' }} />
            </Tooltip>
        </div>
        <div style={{ marginBottom: '24px' }}>
            Full Refresh: <Checkbox
                checked={fullRefresh}
                onChange={(e) => {
                    setFullRefresh(e.target.checked)
                }}
            />
        </div>
        <div style={{ marginBottom: '16px' }}>
            Trigger Dependent Jobs: <Checkbox
                checked={triggerDependentJobs}
                onChange={(e) => {
                    setTriggerDependentJobs(e.target.checked)
                }}
            />
        </div>
        <Form.Item label={"Warehouse Group"}>
		    <Select
			    loading={warehouseGroupsLoading}
			    placeholder="warehouse group"
			    allowClear={true}
			    onChange={(warehouse_group: string) => {
				    setWarehouseGroup(warehouse_group)
			    }}
			    onClear={() => {
				    setWarehouseGroup(null);
			    }}
		    >
			    {warehouseGroups?.warehouse_groups?.map((whg: string) => (
				    <Select.Option
					    key={whg}
					    value={whg}
				    >
					    {whg}
				    </Select.Option>
			    ))}
		    </Select>
	</Form.Item>
        <Button disabled={loadingManualTrigger} type={"primary"}
                onClick={() => {
			const triggerParams: GrindorMultiSelectTriggerBody = {
			    slug: slug,
			    products: products,
			    warehouse_group: warehouse_group,
			    full_refresh: fullRefresh,
                force_trigger: forceTrigger,
                user: userName,
			    ignore_lineage: !triggerDependentJobs,
			};
			manualTrigger(triggerParams);
                }}>
            Trigger
        </Button>
    </div>
}
