import "antd/dist/antd.css";
import React, { useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "./App.css";
import { Button } from "antd";
import Login from "./Login";
import { useIdToken } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import BumblebeeApp from "./bumblebee/BumblebeeApp";
import DrStrange from "./DrStrange/App";
import bumblebeeIcon from './assets/bumblebee_icon.png';

function Dashboard() {
  const [idToken, setIdToken] = React.useState<string>();
  const [user, loading] = useIdToken(auth);
  const environmentNames = {
    "voyantis-api-pro": "PRODUCTION",
    "voyantis-api-dev": "STAGING",
  };
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID as
    | "voyantis-api-pro"
    | "voyantis-api-dev";
  const environmentName = environmentNames[projectId] ?? "UNKNOWN";
  useEffect(() => {
    if (user) {
      user.getIdToken().then(setIdToken);
    } else {
      setIdToken(undefined);
    }
  }, [user, loading])

  if (loading) {
    return <div>Loading..</div>;
  }
  if (!user) {
    return <Login />;
  }

  if (!idToken) {
    return <div>Authenticating...</div>;
  }
  return (
    <Switch>
      <Route path="/bumblebee">
        <BumblebeeApp
          userName={user.email!}
          environment={environmentName}
          token={idToken}
        />
      </Route>
      <Route path="/dr-strange">
        <DrStrange
          userName={user.email!}
          environment={environmentName}
          token={idToken}
        />
      </Route>
      <Route path="/" exact={true}>
        <div style={{ position: "absolute", right: 0 }}>
          <Button type={"primary"} onClick={() => auth.signOut()}>
            Logout
          </Button>
        </div>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "10%",
            transform: "translate(-50%)",
          }}
        >
          <h1 style={{ color: "red" }}>{environmentName}</h1>
        </div>
        <div className="menu">
          <div className="menu-box">
            <div>
              <img
                style={{ height: 200 }}
                alt="Dr. Strange"
                src={
                  "https://i.pinimg.com/originals/87/2d/17/872d17bfed292b2f2021ba130497ed4c.png"
                }
              />
            </div>
            <h1>Dr. Strange</h1>
            <p>Explore the multiverse!</p>
            <Link to="/dr-strange">
              <Button type={"primary"}>Go to Dr. Strange</Button>
            </Link>
          </div>
          <div className="menu-box">
            <div>
              <img
                style={{ height: 200 }}
                alt="Transformers"
                src={bumblebeeIcon}
              />
            </div>
            <h1>Bumblebee</h1>
            <p>Ingestion & Normalization Management</p>
            <Link to="/bumblebee">
              <Button type={"primary"}>Go to Bumblebee</Button>
            </Link>
          </div>
        </div>
      </Route>
    </Switch>
  );
}

export default Dashboard;
